 .align-center {
	 text-align: center;
}
 .get-in-touch >.row {
	 margin: -20px 0;
}
.get-in-touch >.row:after {
	 content: "";
	 display: table;
	 clear: both;
}
.get-in-touch >.row .col {
	 padding: 0 20px;
	 float: left;
	 box-sizing: border-box;
}
 .get-in-touch >.row .col.x-50 {
	 width: 50%;
}
 .get-in-touch >.row .col.x-100 {
	 width: 100%;
}
 .content-wrapper {
	 min-height: 100%;
	 position: relative;
}
 .get-in-touch {
	 margin: 0 auto;
	 position: fixed;
   display: none;
	 top: 50%;
	 transform: translateY(-50%);
   z-index: 18;
     -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
  width: 30vw;
  margin-left: 55vw;
}
 .get-in-touch >.titles {
	 text-align: center;
	 text-transform: uppercase;
	 letter-spacing: 3px;
	 font-size: 36px;
	 line-height: 48px;
	 padding-bottom: 48px;
}
 .contact-form .form-field {
	 position: relative;
	 margin: 32px 0;
}
 .contact-form .input-text {
	 display: block;
	 width: 100%;
	 height: 36px;
	 border-width: 0 0 2px 0;
	 font-size: 18px;
	 line-height: 26px;
	 font-weight: 400;
   color: white;
}
 .contact-form .input-text:focus {
	 outline: none;
}
 .contact-form .input-text:focus + .label, .contact-form .input-text.not-empty + .label {
	 transform: translateY(-24px);
}
 .contact-form .label {
	 position: absolute;
	 left: 20px;
	 bottom: 11px;
	 font-size: 18px;
	 line-height: 26px;
	 font-weight: 400;
	 color: #888;
	 cursor: text;
	 transition: transform 0.2s ease-in-out;
   margin-bottom: 1.5rem !important;
}
 .contact-form .submit-btn {
	 display: inline-block;
	 background-color: #000;
	 color: #fff;
	 text-transform: uppercase;
	 letter-spacing: 2px;
	 font-size: 16px;
	 line-height: 24px;
	 padding: 8px 16px;
	 border: none;
	 cursor: pointer;
}
 .note {
	 position: absolute;
	 left: 0;
	 bottom: 10px;
	 width: 100%;
	 text-align: center;
	 font-size: 16px;
	 line-height: 21px;
}
 .note .link {
	 color: #888;
	 text-decoration: none;
}
 .note .link:hover {
	 text-decoration: underline;
}
 
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300&display=swap');

*
{
    margin: 0;
    padding: 0;
    font-family: 'Source Code Pro', monospace;
    /* font-family: 'Inconsolata', monospace;    cursor: url("https://www.mshaircuts.ch/wp-content/uploads/2021/08/circle-1.png") 5 5, move;; */
}

html,
body
{
   
    background: #000000;
	/* cursor: url("https://www.mshaircuts.ch/wp-content/uploads/2021/08/oval.png"),auto; */
}
/*grid test*/


a{
  color: white;
}

/*Animation*/

@-webkit-keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}

@-webkit-keyframes fadeOut {
  from { opacity: 1; }
    to { opacity: 0; }
}
@keyframes fadeOut {
  from { opacity: 1; }
    to { opacity: 0; }
}


.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    z-index: 10;
}
.webglBG
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    z-index: 1;
    mix-blend-mode: exclusion;
}

.title{
    text-align: center;
    color: white;
  height: 100vh;
  display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
  
  justify-content: center;
  align-items: center;
}
.horizon{
  background: transparent;
  filter: alpha(opacity=50);
  z-index: 5;
}
#fix{
  height: 100vh;
  display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
  text-align: center;
  justify-content: center;
  align-items: center;
}

.logo > img{
  
  height: 75vh;
      margin-left: auto;
    margin-right: auto;
    display: block;
    background: transparent;
    z-index: 3;

}

 .burger {
  width: 64px;
  height: 64px;
  border-top: 0;

  position: fixed;
  top: 20px;
  left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
transform: rotate(0deg);
  z-index: 98;
}
#span{
  color: #000000 !important;
}

  .burger span {
      height: 2px;
      width: 15px;
      background: black;
      margin: 2px;
      z-index: 99;
  }

      .burger:hover {
          cursor: pointer;
      }

         
.menu {
    width: 100%;
    height: 100vh;
    background: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    transition: all 0.4s;
}

    .menu_active{
        transform: translateX(0%);
        transition: all 0.4s;
    }

    .menu .btn-close {
        position: absolute;
        top: 20px;
        right: 20px;
        text-align: center;
        font-size: 40px;
        line-height: 20px;
        height: 25px;
        width: 25px;
        color: white;
        padding: 0;
        border: 0;
    }

        .menu .btn-close:hover {
            color: white;
            transform: scale(1.5);
            
        }

    .menu ul {
        list-style-type: none;
    }

        .menu ul li {
            margin: 10px;
            font-size: 25px;
            color: white;
            font-weight: 700;
            text-align: center;
        }
        .menu ul li:hover {
          margin: 10px;
        }
        

            .menu ul li a {
                color: #ffffff;
                text-decoration: none;
                font-weight: 700;
            }

                .menu ul li a:hover {
                    color: red;
                    transform: rotate(20deg);
                }

                #topp{
                  width: 128px;
                  height: 128px;
                  margin-bottom: 100px;
                  margin-top: -100px;
                }
/* Cursor custom  */
.cursor {
  pointer-events: none;
}
.cursor__ball {
  position: fixed;
  top: 0;
  left: 0;
  mix-blend-mode: difference;
  z-index: 1000;
}
.cursor__ball circle {
  fill: #f7f8fa;
  
}
/* Preloader */
#loading {
  position: fixed;
  display: flex;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  opacity: 1;
  background-color: #ffffff;
  z-index: 99;
}

#loading-image {
  display: block;
  margin: auto auto;
  height: 75vh;
  z-index: 100;

}

@media only screen and (max-width: 1100px) {
  #loading-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 30vh;
    z-index: 100;
  
  }
}



/* Contact form */
@import url(https://fonts.googleapis.com/css?family=Inconsolata:400,700);

/* Custom cursor  */
#cursor {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  pointer-events: none;
  will-change: transform;
  mix-blend-mode: difference;
  
}

@media (hover: hover) and (pointer: fine) {
  .cursor__circle {
    width: 32px;
    height: 32px;
    margin-top: -50%;
    margin-left: -50%;
    border-radius: 50%;
    border: solid 1px rgba(227, 222, 193, 0.64);
    transition: opacity 0.3s cubic-bezier(0.25, 1, 0.5, 1),
      background-color 0.3s cubic-bezier(0.25, 1, 0.5, 1),
      border-color 0.3s cubic-bezier(0.25, 1, 0.5, 1),
      width 0.3s cubic-bezier(0.25, 1, 0.5, 1),
      height 0.3s cubic-bezier(0.25, 1, 0.5, 1);
      background: white;
  }
  
  #cursor.arrow .cursor__circle{
    width: 128px;
    height: 128px;
    background-color: #990707;
    border-color: #990707;
  }
  
  #cursor.arrow::after{
    content: '';
    position: absolute;
    left: -50%;
    top: -50%;
    width: 100%;
    height: 100%;
    /* background-image: url('https://svgshare.com/i/MzQ.svg');
    background-repeat: no-repeat;
    background-position: center; */
  }
  
  #cursor.subtle .cursor__circle{
    opacity: 0.16;
  }
  
  #cursor.overlay .cursor__circle{
    width: 48px;
    height: 48px;
    background-color: rgba(227, 222, 193, 0.08);
    border-color: transparent;
  }

  .space{
      margin-bottom: 300cm;
  }

  main{
      position: absolute;
  }
}


/* About section */
@import url(https://fonts.googleapis.com/css?family=Khula:700);

.hidden {
  opacity:0;
}

.console-container {
 
  font-size:3em;
  text-align:left;
  height:100vh;
  width:80vw;
  display:block;
  color:white;
  top:50vh;
  bottom:0;
  right:5vw;
  margin:auto;
  padding-right:25vw;
  position: fixed;

}
.console-container1 {
 
  font-size:3em;
  text-align:left;
  height:100vh;
  width:80vw;
  display:block;
  color:white;
  top:50vh;
  bottom:0;
  right:5vw;
  margin:auto;
  padding-right:25vw;
  position: fixed;

}
.console-underscore {
  display:inline-block;
  position:relative;
  top:-0.14em;
  left:10px;
}
#about{
  display: none;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
  z-index: 12;

}
#about_1{
  display: none;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
  z-index: 12;
    opacity: 0;

}

/* @media only screen and (min-width: 721px) {

#text_fr{
  opacity: 0;
}
#text_en{
  opacity: 1;
}
} */



/*Footer*/
#footer{
  height: 100vh;
  color: white;
  text-align: center;
  left: 0;
    top: 10vh;
    right: 0;
    position: fixed;
    display: none;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  z-index: 14;
}
.thank{
  padding-top: 10vh;
}
#espace{
  margin-top: 40vh;
}
.site-footer .social-icons
{
  text-align:right
}
.site-footer .social-icons a
{
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:#33353d
}
.copyright-text
{
  margin:0
}
@media (max-width:991px)
{
  .site-footer [class^=col-]
  {
    margin-bottom:30px
  }
}
@media (max-width:767px)
{
  .site-footer
  {
    padding-bottom:0
  }
  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:center
  }
}

.container{
  max-width: 100vw !important;
  font-size: 23px !important;
display: flex;
}
.rows{
  display: contents;
}
::marker {
color: transparent;
}
#end_bottom{
  
  margin-right: 24px;

}

/*Portfolio idea*/



/* Create two unequal columns that floats next to each other */
.column {
  float: left;
  padding: 10px;
  
 /* Should be removed. Only for demonstration */
}
.row{
  width: 99vw;
  display: table-cell;
  justify-content: center;
  align-items: center;
  padding-left: 1.5vw;
 top: 1px;
}
#portfolio{

-webkit-animation: fadeIn 1s;
animation: fadeIn 1s;
    z-index: 12;

}

.left {
  height: 100vh;
  width: 25%;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
  color: white;
  margin-top: 4%;
}
.left ul li{
  padding: 15px;
  border: 2px solid;
  border-radius: 20px;
  margin-bottom: 10px;
  border-color: white;
}
.left ul a{ font-size: 2vh;
}
.right {
  width: 75%;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
.left ul{
  margin-left: auto;
  margin-right: auto;
}

#proj{
  width: 39.3vw;
  margin: auto;
  display: block;
}

/*Hover*/
.left ul li:hover{
  background-color: white;
  color: black;
}


/*Button*/

::-webkit-scrollbar {
  display: none;
}


/*----------------------------Mobile Version CSS---------------------------------- */
@media only screen and (max-width: 1100px) {
main{
display: none;
}
.title{
  height: 101vh;
}
/* About section */
.console-container2 {
 
  font-size:3em;
  text-align: center;
  text-align:justify;
  height:100vh;
  width:80vw;
  display:block;
  color:white;
  top:50vh;
  bottom:0;
  margin:auto;
  position: fixed;
  padding-right: 0;

}
.console-underscore {
  display:none;
  position:relative;
  top:-0.14em;
  left:10px;
}
#about1{
  position: initial;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
  z-index: 12;
  font-size:25px;
  text-align:justify;
  height:100vh;
  width:80vw;
  display:flex;
  color:white;
  top:0;
  bottom:0;
  margin:auto;
  align-items: center;
  text-align: center;
  padding: 0;
  margin-bottom: 50px;
}
#about_11{
  position: initial;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
  z-index: 12;
  font-size:25px;
  text-align:justify;
  height:100vh;
  width:80vw;
  display:none;
  color:white;
  top:0;
  bottom:0;
  margin:auto;
  align-items: center;
  padding: 0;
  margin-bottom: 50px;
}
#text_fr_mobile{
  text-align: center;
}
#text_en_mobile{
  text-align: center;
}

/* Portfolio section */

.list{
  padding: 9vw;

}
:root {
    --effect-height: 300vh;
    --rotation-angle: -20deg;
    --image-gap: 10px;
  
    --images-per-column: 6;
    --columns-per-page: 3; 
}

/* Controls the angle at which the images are displayed */
.backdrop{
  -ms-transform: rotate(var(--rotation-angle)); /* IE 9 */
  -webkit-transform: rotate(var(--rotation-angle)); /* Safari */
  transform: rotate(var(--rotation-angle));
  overflow: hidden;
  transform: scale(1);
  z-index: 8;
  left: 0;
  top: -22vw;
}
#backdrop{
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
  margin-top: -25vh;
  margin-bottom: -135vh;
}
/* Makes sure that each EVEN column is shifted down by half a picture height */
.backdrop .colum:nth-child(even) {
  position: relative; /* Needed for "top" to work */
  top: calc(var(--effect-height) / var(--images-per-column) ); /* /2 because we want to shif down by HALF an image height*/
}
.element{
  display: block;
  width: calc(100% - var(--image-gap) * 2);/* Set the element's width to 100% of the column it is inside. -image gap * 2 because there is a gap on both sides of the image */
  /* height: calc(var(--effect-height) / var(--images-per-column) - var(--image-gap) * 2); */
  border-radius: 25px;
  
  background-size: 100% 100%; /* Ensures that the images stretches to fill the boundary */
  margin: var(--image-gap);
  position: relative;
}
.elements{
  display: block;
  width: calc(100% - var(--image-gap) * 2);/* Set the element's width to 100% of the column it is inside. -image gap * 2 because there is a gap on both sides of the image */
  /* height: calc(var(--effect-height) / var(--images-per-column) - var(--image-gap) * 2); */
  border-radius: 25px;
  position: relative;
  
  background-size: 100% 100%; /* Ensures that the images stretches to fill the boundary */
  margin: var(--image-gap);
}
.colum{
  width: calc(100% / var(--columns-per-page));
  height: var(--effect-height);
  display: inline-block;
  overflow-y: visible !important;
  transform: scale(1.1);
}

.list{
    overflow: hidden;
}
/* Contact form */

/*Footer*/
#footer1{
  height: 100vh;
  color: white;
  text-align: center;
  left: 0;
    top: 10vh;
    right: 0;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  z-index: 14;
  padding-top: 20vh;
}
.thank{
  padding-top: 10vh;
  font-size: 2rem;
}
#espace{
  margin-top: 40vh;
}
.site-footer .social-icons
{
  text-align:right
}
.site-footer .social-icons a
{
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:#33353d
}
.copyright-text
{
  margin:0;
  font-size: 1rem;
}
@media (max-width:991px)
{
  .site-footer [class^=col-]
  {
    margin-bottom:30px
  }
}
@media (max-width:767px)
{
  .site-footer
  {
    padding-bottom:0
  }
  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:center
  }
}

.container{
  max-width: 100vw !important;
  font-size: 23px !important;
display: flex;
}
.rows{
  display: contents;
}
::marker {
color: transparent;
}
#end_bottom{
  
  margin-right: 24px;

}

/*--------- Contact ---------*/
.align-center {
	 text-align: center;
}
 .get-in-touch1 >.row {
	 margin: -20px 0;
}
.get-in-touch1 >.row:after {
	 content: "";
	 display: table;
	 clear: both;
}
.get-in-touch1 >.row .col {
	 padding: 0 20px;
	 float: left;
	 box-sizing: border-box;
}
 .get-in-touch1 >.row .col.x-50 {
	 width: 50%;
}
 .get-in-touch1 >.row .col.x-100 {
	 width: 100%;
}
 .content-wrapper {
	 min-height: 100%;
	 position: relative;
}
 .get-in-touch1 {
	 margin: 0 auto;
	 position: initial;
	 top: 50%;
   z-index: 18;
     -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
  width: 100vw;
}
 .get-in-touch1 >.titles {
	 text-align: center;
	 text-transform: uppercase;
	 letter-spacing: 3px;
	 font-size: 36px;
	 line-height: 48px;
	 padding-bottom: 48px;
}
 .contact-form .form-field {
	 position: relative;
	 margin: 32px 0;
}
 .contact-form .input-text {
	 display: block;
	 width: 100%;
	 height: 36px;
	 border-width: 0 0 2px 0;
	 font-size: 18px;
	 line-height: 26px;
	 font-weight: 400;
   color: white;
}
 .contact-form .input-text:focus {
	 outline: none;
}
 .contact-form .input-text:focus + .label, .contact-form .input-text.not-empty + .label {
	 transform: translateY(-24px);
}
 .contact-form .label {
	 position: absolute;
	 left: 20px;
	 bottom: 11px;
	 font-size: 18px;
	 line-height: 26px;
	 font-weight: 400;
	 color: #888;
	 cursor: text;
	 transition: transform 0.2s ease-in-out;
   margin-bottom: 1.5rem !important;
}
 .contact-form .submit-btn {
	 display: inline-block;
	 background-color: #000;
	 color: #fff;
	 text-transform: uppercase;
	 letter-spacing: 2px;
	 font-size: 16px;
	 line-height: 24px;
	 padding: 8px 16px;
	 border: none;
	 cursor: pointer;
}
 .note {
	 position: absolute;
	 left: 0;
	 bottom: 10px;
	 width: 100%;
	 text-align: center;
	 font-size: 16px;
	 line-height: 21px;
}
 .note .link {
	 color: #888;
	 text-decoration: none;
}
 .note .link:hover {
	 text-decoration: underline;
}
.logo > img{
  height: 30vh;
}
}
#lang p {
        position: absolute;
        top: 15px;
        left: 40px;
    }

#top_bar{
  background-color: black;
  position: fixed;
  height: 11vh;
  width: 100vw;
  z-index: 19;
}
.burger{z-index: 20;}

#progress{
  height:3px;
  background:black;
  position:absolute;
  width:0;
  top:90%;
}
#progstat{
  font-size:0.7em;
  letter-spacing: 3px;
  position:absolute;
  top:90%;
  margin-top:-40px;
  width:100%;
  text-align:center;
  color:black;
}